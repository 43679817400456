
/* @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500;700&family=Noto+Sans+TC:wght@300;400;500&display=swap'); */


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.web3modal-modal-lightbox { z-index: 9999 !important; }

@tailwind base;
@tailwind components;
@tailwind utilities;
