article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:after, :before {
  box-sizing: border-box;
}
body {
  font-family: 'Noto Sans TC', Arial, sans-serif;
  font-size: 15px;
  line-height: 1.5;
  letter-spacing: 1px;
  font-weight: 300;
  background-color: var(--dark-color);
  position: relative;
}
img {
  max-width: 100%;
  height: auto;
}

button:focus,
a:focus {  outline: none;}
a {  text-decoration: none;  color: #303031;}
a:hover {  text-decoration: none;  color: #303031;}
ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

:root {
  --main-color: #E1BD7C;
  --sub-color:#F7EAAD;
  --yellow-color: #FFCE3E;
  --gray-color: #F6F6F6;
  --white-color: #FFFFFF;
  --dark-color: #000000;
}

.w1400 {
  max-width: 1366px;
  height: 100%;
}

.content_block {
  max-width: 1920px;
  margin: auto;
  overflow-y: hidden;
  position: relative;
}
.container {
  padding:0 1rem;
  margin: auto;
  width: 100%;
}

.title {
  font-size: 1.65rem;
  font-weight: 700;
  font-family: 'Cairo' ,Arial;
}

.title-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

/* 共用標題 */
.title-box {
  color: var(--main-color);
  padding-bottom: .8rem;
  text-align: center;
  position: relative;
  margin-bottom: 1.8rem;
}

.title-box:after {
  content: '';
  background: url(../images/title_line.png) no-repeat center;
  position: absolute;
  left: 50%;
  bottom: -20px;
  width: 300px;
  margin-left: -150px;
  height: 30px;
  background-size: contain;
}

.title-box span {
  font-weight: 100;
  display: block;
  font-size: 1rem;
}
/* 共用按鈕 */
.black-btn{
  background: var(--dark-color);
  color: var(--yellow-color);
  border: 1px solid var(--yellow-color);
  text-shadow: 0 5px 15px rgb(0 0 0 / 35%);
  font-weight: 400;
  padding: 0.8rem;
  transition: all .5s;
}
.black-btn:hover {
  background: linear-gradient(to bottom, hsla(18, 99%, 65%, 0.6) 0%, hsla(43, 100%, 63%, 0.6) 100%);
  color: var(--white-color);
}
.yellow-btn {
  background: linear-gradient(to bottom, #FE844E 0%, #FFC943 100%);
  color: var(--white-color);
  font-weight: 400;
  text-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
.yellow-btn:hover{
  background: linear-gradient(to bottom, hsla(43, 100%, 63%, 0.6) 0%, hsla(18, 99%, 65%, 0.6) 100%);
  font-weight: 400;
  color: var(--white-color);
}
.green-btn{
  background: linear-gradient(to bottom, #2CBAA9 0%, #51FFEA 100%);
  color: var(--white-color);
  font-weight: 400;
  text-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
.green-btn:hover{
  background: linear-gradient(to top, #2CBAA999 0%, #51FFEA99 100%);
  color: var(--white-color);
}
.btn {
  border-radius: 5px;
  text-align: center;
  padding: 8px;
  display: block;
  font-weight: 400;
  cursor: pointer;
}

@media (min-width: 576px){
  .container {
    width: 546px;
  }
  
}
@media (min-width: 768px) {
  .container {
    width: 738px;
  }
}
@media (min-width: 992px) {
  .w1400 {
    margin: auto;
  }
  .container {
    width: 962px;
  }
  .title{
    font-size: 1.8rem;
  }
  .title-box span {
    font-size: 1.125rem;
  }
  
}

@media (min-width: 1200px){
  .container {
    width: 1170px;
  }
}
@media (min-width: 1400px) {
  .container {
    width: 1370px;
  }
}

.modal-box{
  position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    opacity: .5;
    height: 100vh;
    left: 0;
    overflow: hidden;
    top: -100vh;
    transition: all .5s ease-in;
    z-index: 500;
}
.modal-box.check{
  position: fixed;
  top: 0;
  opacity: 1;
}
.mask{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-box .inner{
  position: relative;
  margin-left: 15px;
  margin-right: 15px;
  z-index: 2;padding: 1px;
  background: linear-gradient(to right, #bb7239 0%, #fceeae 16%, #e09e5b 36%, #fce9a4 70%, #f1d693 77%, #e1bd7c 88%, #d08a4a 100%);
  max-width: 600px;width: 600px;min-height: 280px;

}
.modal-box .inner:before{
  content:'';
  position: absolute;
  background: var(--dark-color);
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  display: block;
  z-index: 2;
}
.modal-box .modal-header {
  position: relative;
  text-align: right;
  z-index: 5;color: var(--sub-color);
 }
 .modal-box  .closemodal{
  position: absolute;
  z-index: 6;
  right: 5px;
  top: 5px;
  color: var(--main-color);
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.modal-box  .modal-header svg{  cursor: pointer;}
.modal-box  .modal-text{
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 5;
  color: var(--white-color);padding: 5rem 3rem;
}
.modal-box  .modal-text p{margin-bottom: 1.5rem;line-height: 1.8;}
.modal-box  .modal-text p span{display: block;color: var(--main-color);}
.modal-box  .modal-text p span i{color: var(--white-color);font-style: normal;}
.modal-box  .modal-text p a{color:var(--sub-color);text-decoration: underline;}
/* 手機漢堡選單 */


.menu-icon__cheeckbox {
  display: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;
  position: absolute;
  opacity: 0;
}
.menu-icon {
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: none;
}

.menu-icon div {
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 25px;
  height: 12px;
}

.menu-icon span {
  position: absolute;
  display: block;
  width: 100%;
  height: 3px;
  background-color: var(--main-color);
  border-radius: 1px;
  transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);
}

.menu-icon span:first-of-type {
  top: 0;
}

.menu-icon span:last-of-type {
  bottom: 0;
}

.menu-icon__cheeckbox:checked~.menu-icon div span:first-of-type {
  transform: rotate(45deg);
  top: 4px;
}

.menu-icon__cheeckbox:checked~.menu-icon div span:last-of-type {
  transform: rotate(-45deg);
  bottom: 5px;
}

/* 手機漢堡選單end */
/* 主選單開始 */
.navbar {
  padding: 0;
  width: 100%;
  position: fixed;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
  top: 0;
  left: 0;
  z-index: 100;
  
}
.navbar-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.navbar-header .navbar-collapse {
  flex: 0 0 calc(100% - 220px);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.nav li a{ color: var(--white-color); padding: .8rem;display: block;transition: all .5s ease;}
.nav li a:hover{
  color: var(--yellow-color);font-weight: 500;
}
.nav li.active a {
  color: var(--yellow-color);
  font-weight: 500;
}

.nav_other li a {
  padding: 0.3rem 0.8rem;
  margin: 2px 3px ;
  border-radius: 3rem;
  color: var(--white-color);
}
.menu-lang-cheeckbox,
.menu-wallet-cheeckbox {
  display: none;
}

.mblang{
  display: none;
}
.pclang{
  display: block;
}
.nav_other .lang a {
  color: var(--white-color);
  padding: .5rem;display: block;
}
.menu-lang,
.menu-wallet{cursor: pointer;}
.lang-btn {
  display: none;  width: 100%;font-size: 15px;
  background-color: #3d3d3d80;
  border: #FFCE3Ea1 1px solid;
  transition: all .3s ease-in;
}
.wallet-modal {
  max-width: 420px;
  width: calc(100% - 40px);
  position: absolute;
  z-index: 1;
  top: -100%;right: 20px;
  padding: 1px 1px 2rem 1px;
  background: linear-gradient(to right, #bb7239 0%, #fceeae 16%, #e09e5b 36%, #fce9a4 70%, #f1d693 77%, #e1bd7c 88%, #d08a4a 100%);
}
 .menu-wallet-cheeckbox:checked~.modal-bg {
  background-color: #00000097;width: 100%;height: 100vh;position: absolute;left: 0;top: 0;
}

 .menu-wallet-cheeckbox:checked~.wallet-modal {
   transform: translate(-50%, -100%);
   left: 50%;
   top: 50%;
 }
 .modal::before{
   content: '';
   position: absolute;
 }
 .modal::before{
      background: var(--dark-color);
      height: calc(100% - 2px);
      width: calc(100% - 2px);
      display: block;
      z-index: 2;
 }

.modal .border::before,
.modal .border::after {
   content: '';
   background: url(../images/join_border.png) no-repeat;
   background-size: contain;
   display: block;
   position: absolute;
   width: 50px;
   height: 50px;
   z-index: 3;
 }
.modal .border::before{
  left: 0;top: 0;
}
.modal .border::after {
  right: 0;
  bottom: 0;
  transform: rotate(-180deg);
}
.wallet-modal .modal-header {
  display: flex;
  justify-content: space-between;
  color: var(--sub-color);
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 1rem 20px;
}
.wallet-modal .modal-header .title{
   font-size: 1.45rem;padding-left: 20px;
}
.wallet-modal .modal-header  .close{cursor: pointer;}
.wallet-modal .items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;align-content: flex-start;
  position: relative;text-align: center;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.wallet-modal .items li{flex: 0 0 40%;display: flex;justify-content: center;}
.wallet-modal .items li .img{background-color: var(--white-color); border-radius: 50%;height: 5rem;width: 5rem;margin:0 auto 1rem; display: flex;justify-content: center;align-items: center;}
.scroll_btn {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 30;width: 100%;
}
.scroll_btn ul {
  display: flex;
  width: 100%;
  background: linear-gradient(to bottom, rgba(247, 234, 173, 1) 0%, rgba(225, 189, 124, 1) 100%);
  border-top: 1px solid var(--sub-color);
}
.scroll_btn ul li{flex:1;}

.scroll_btn li a {
  color: var(--dark-color);display: flex;align-items: center;justify-content: center;
  height: 100%;
  position: relative;
  font-weight: 400;
  padding:.5rem 5px;
}
.scroll_btn ul li.active a{
  background: linear-gradient(to top, rgb(231, 206, 96) 0%, rgba(225, 189, 124, 1) 100%);
}
.scroll_btn li:not(:last-child) a:after{
  content: '';
  width: 1px;
  height: 100%;
  display: block;
  background-color: var(--white-color);
  opacity: .6;
  position: absolute;
  top: 50%;right: 0; transform: translateY(-50%);
}
.community{position: fixed;right: 8px;top: 30%;z-index: 5;}
.community li a{
  width: 40px;
  display: block;
  margin-bottom: 5px;

}
@media (min-width: 1280px) {
  .navbar-nav {
    flex-direction: row;
  }

  .navbar-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
  }
  .navbar-header {
    padding: 0 2%;
    max-width: 1920px;
    margin:0 auto;
  }
  .navbar-header .navbar-collapse{
    justify-content: space-between;
    align-items: center;
    flex-direction:row;
  }
  .nav {
    display: flex;
  }
  .nav li a {
    padding: 1rem ;
  }
  .nav_other {
    
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .lang-btn{display: none;width: 7rem;}
  .lang:hover .lang-btn {display: block;position: absolute;top: 55px;}
  .lang .menu-lang:after{
    display: none;
  }
 
  #menu-wallet:checked~.wallet-modal {
    display: block;
  }
  .wallet-modal{
    transform:translate(-100% , -100%);
  }
  .menu-wallet-cheeckbox:checked~.wallet-modal{
    transform:translate(-50%, 100%);
  }

  .scroll_btn {
    top: 30%;
    width: auto;
  }
  .scroll_btn ul {
    background:transparent;
    display: block;
    width: 118px;
    border-top: none;
  }

  .scroll_btn li a {
    padding: 5px 10px 5px 30px;
    margin-bottom: 5px;
    color: var(--white-color);
    justify-content: flex-start;
  }
  .scroll_btn ul li.active a {
    background:transparent}
  .scroll_btn li:not(:last-child) a:after {
    display: none;
  }

  .scroll_btn li a:before {
    content: '';
    width: 12px;
    height: 12px;
    display: block;
    border-radius: 50%;
    border: 2px solid var(--main-color);
    background-color: var(--dark-color);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
   .scroll_btn ul li.active a:before {
    background-color: var(--main-color);
   }

}
/* 手機語言選單 */
@media (max-width: 1280px) {
  /* .nav_other .menu-lang {
    position: relative;
    width: 100%;
    display: block;
  }
  #menu-lang:checked~.lang-btn {
    position: relative;
    display: block;
    opacity: 1;

  } */

  .mblang{
    display: flex;
  }
  .pclang{
    display: none;
  }
  .nav_other {
    
    position: relative;
    width: 100%;
    display: block;
  }
  .lang-btn{display: none;width: 7rem;}
  .lang:hover .lang-btn {display: block;position: absolute;top: 55px;}
  .lang .menu-lang:after{
    display: none;
  }
 
  #menu-wallet:checked~.wallet-modal {
    display: block;
  }
}
/* 主選單結束 */
.logo {
  margin-bottom: 0;
  width: 220px;
  flex:0 0 220px;
  padding: 5px 10px;
}

.logo a{
  background: url(../images/logo.svg)no-repeat center/cover;
  padding-bottom: 18%;
  display: block;
  margin: .5rem;
}


@media (min-width: 992px) {
  .logo {padding: 5px;}
}

@media (min-width: 1200px) {
  .logo a {
    width: auto;margin: .8rem .5rem;
  }
}

@media (max-width: 1280px) {
   .menu-icon {
     display: block;
   }
  .navbar-collapse {
    overflow-y: auto;
    background-color: var(--dark-color);
    height: 100vh;
    text-align: center;
    position: absolute;
    top: 0px;
    left: -100%;
    z-index: -1;
    width: 100%;
    padding:60px 1rem 1rem;
    opacity: .5;
    transition: all .5s ease;
  }
   #menu-link:checked~.navbar-collapse {
     opacity: 1;
     left: 0;
     visibility: visible;
   }
}
footer {
  border-top: 1px solid var(--sub-color);
  padding: 1.5rem 0 4.5rem;
}

footer .txt {
  text-align: center;
  color: var(--sub-color);
  font-size: 85%;
}

footer .txt .copy {
  color: #636363;
}

footer .txt .copy a,
footer .txt .copy a:hover {
  color: #636363;
  text-decoration: underline;
}

@media (min-width: 992px) {
  footer {
    padding: 1.5rem 0;
  }
}
/* 首頁廣告 */
/* .AD{position: absolute;width: 100%;height: 100%;z-index: 10;} */
.AD .item{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.AD .item .inner{
    position: relative;
    z-index: 2;
    background-color: #fff;
    transition: all 0.7s;
}
.AD .item .inner .close {
  position: absolute;
  right: -23px;
  top: -23px;
  z-index: 1;
  width: 46px;
  height: 46px;
  color: var(--main-color);
}
.AD .item .inner .popContent img{display: block;
  max-width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;}


.AD .closeAD{position: absolute;z-index: 15;right: 5px;top: 5px;color: var(--main-color);width: 40px;height: 40px; cursor:pointer}


/* 首頁大圖內容 */
.banner-box {
  position: relative;
  padding-top: 56px;
  background:url(../images/story_bg.png)no-repeat top center/ cover;
}
.banner-box .img{
  padding-bottom: 62%;
  background-repeat: no-repeat;
  background-position: 100% bottom;
  background-size: cover;
  background-image:url(../images/banner.png);
}
.banner-txt{
  display: ;
  background: url(../images/story_bg.png) no-repeat top center;
  color: var(--white-color);
  padding: 1rem;
}
.banner-txt .title{
  display: none;
  color:var(--yellow-color);
  line-height: 1.2;
  margin-bottom: 1rem;
}
.banner-txt p{ font-weight: 300;display: none;}
.banner-txt .button{margin-top: 1.5rem; display: flex;flex-wrap: wrap;justify-content: center;}
.banner-txt .btn{
  flex:1 0 45%;
  padding: .8rem;
  margin:0 1% 1.25rem ;
  
}


/* 倒數計時 */
.time-box{
  position:relative;
  width: 100%;
  margin-bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  padding:1rem ;
  color: var(--main-color);background-color: #000000a3;border: 1px solid var(--yellow-color);
}
.time-box:before,
.time-box:after,.time-box .light {
   content: '';
   position: absolute;
}
.time-box:before{
  background: url(../images/light1.png) no-repeat center/contain;
  width: 300px;
  height: 78px;
  bottom: -37px;
  right: 0;
}
.time-box:after{
  background: url(../images/light2.png) no-repeat center/contain;
  width: 27px;
  height: 193px;
  left: -13px;
  top: -58px;
}
.time-box .light{
  background: url(../images/light3.png) no-repeat center/contain;
  width: 310px;
  height: 6px;
  top: -3px;right: 0;
}
.activityTime{
   display: flex;
   flex-wrap: wrap;
}
.activityTime p:first-of-type{
  color: var(--main-color);padding-right: 1rem;
  display: flex;margin-bottom: 5px;
  align-items: center;
}
.activityTime p~p{  
  font-family: 'Cairo', 'Arial';
  line-height: .8; 
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  }
.activityTime .number {
  font-size: 1.8rem;
  font-weight: 500;
}
.activityTime .colon {
  font-size: 1.5rem;
  display: flex;align-items: center;
  font-family:  'Arial';
}
.activityTime span{ color: var(--yellow-color); padding:0 5px}

@media (min-width: 992px) {
 .banner-box{ padding-top: 0;}
 .banner-box .img {
  padding-bottom: 42%;
  background-size: cover;
  background-position: right bottom;
 }
 .banner-txt {
  background: transparent;
  position: absolute;
  width: 40%;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);
 }
 .banner-txt .title{font-size: 2.8rem;display: block;}
 .banner-txt p {display: block;}
 .banner-txt span{display: block;margin-bottom: 3px;}
 .banner-txt .btn {
   margin: 0 .8rem 1.25rem 0;
 }
 .time-box {
     width:650px;
     padding:1rem 1.5rem;
 }
 .activityTime{   flex-wrap: nowrap;}
 .activityTime .number {
   font-size: 3.6rem;
}
 .activityTime .colon {
   font-size: 3rem;
}
.activityTime p:first-of-type{
  margin-bottom: 0;
}
.activityTime p~p {
  width: auto;
}
}
@media (min-width: 1200px){
   .banner-txt .button {
     width: 320px;
   }
   .time-box {
      margin-top: -7%;
   }
}

/* 故事背景 */
.home-story{
  padding: 2rem 0 4rem;
  background: url(../images/story_bg.png) no-repeat center center;
}

.home-story .txt {
  position: relative;
  text-align: center;
  padding:3rem  1.5rem 1rem;
  margin:1.2rem auto 2rem;
  color: var(--white-color);
}
.home-story .txt p{margin-bottom: 1rem;}
.home-story .txt:before{
  content:'';
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0;top: 0;
  background:url(../images/story_border.png) no-repeat top center /contain;
  z-index: 1;
}
.home-story .txt:after {
  content:'';
  background:url(../images/story_border.png) no-repeat bottom center /contain;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transform: rotate(-180deg);
}
.home-story .txt .txt-border:after{
 
  content:'';
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background:url(../images/story_border2.png) repeat-y 0 center /contain;
}
@media (min-width: 992px) {
  .home-story {
    padding: 6rem 0 4rem;
  }
  .home-story .txt{
    width: 950px;
    padding:3rem 3rem 0;
    margin:1.2rem  auto 2rem;
  }
  .home-story .txt:after {
    margin-top: -1.5rem;
  }
}
/* 種族介紹 */
.home-characters{padding: 0;}
.characters-items ul{display: flex; flex-wrap: wrap;justify-content: center;}
.characters-items ul li {
  flex: 0 0 calc(100% - 30px);
  color: var(--sub-color);
  margin: 1rem 15px 2rem;
}
.characters-items ul li .img{
  padding-bottom: calc(100% - 4px);
  border-radius: 1rem;
  margin:0 0 1rem;
  border:2px solid var(--sub-color);
  position: relative;
  overflow: hidden;
 
}
.characters-items ul li .img img{width: 100%;position: absolute; height: 100%;
  border-radius: 1rem;
  display: block;
  width: 100%;
  left: 0;top: 0;
}

.characters-items ul li .img span{
  position: absolute; height: 100%;
  border-radius: 1rem;
  display: block;
  width: 100%;
  left: 0;top: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-animation: round 5s linear infinite;
  animation: round 5s linear infinite;
}
.characters-items ul li .img:hover span {
   -webkit-animation-play-state: paused;
   animation-play-state: paused;
}

.characters-items ul li .img span:nth-child(2) {
  animation-delay: 1s;
}
.characters-items ul li .img span:nth-child(3) {
  animation-delay:2s;
}
.characters-items ul li .img span:nth-child(4) {
  animation-delay: 3s;
}
.characters-items ul li .img span:last-child{
  animation-delay: 4s;
}
@keyframes round {
   4% {
     opacity: 1;
     filter: alpha(opacity=100);
   }

   20% {
     opacity: 1;
     filter: alpha(opacity=100);
    }

   24% {
     opacity: 0;
     filter: alpha(opacity=0);
   }

}

.characters-items ul li .title{font-size: 1.4rem;text-align: center;margin-bottom: .5rem;padding-bottom: 1.25rem; font-weight: 600; background: url(../images/characters-border.png) no-repeat bottom center;}


@media (min-width: 768px){
   
    .characters-items ul li {
      flex: 0 0 47%;
      margin:1rem 1.5% 2rem;
    }
}
@media (min-width: 992px) {
  .home-characters {
    padding: 4rem 0;
  }
  .characters-items ul {
    width: 1000px;
    margin: auto;
  }
  
  .characters-items ul li {
    flex: 0 0 29.33%;
    margin:1rem  1.5% 2rem;
  }
  .characters-items ul li .title {
    font-size: 1.5rem;
  }
  .characters-items ul li .txt{
    min-height: 120px;
  }
}
/* 特點 */
.home-features{padding: 2rem 0 1rem; background: url(../images/join_bg.png) repeat-y top center/cover;}
.feature-items ul{display: flex;flex-wrap: wrap;justify-content: center; margin-top: 3rem;}
.feature-items ul li {
  color: var(--sub-color);
  position: relative;
  flex:0 0 100%;
  margin: 0rem 2% 3rem;
}
.feature-items ul li::before,
.feature-items ul li::after {
  content: '';
  position: absolute;
}
.feature-items ul li::before {
  background: linear-gradient(to right, #bb7239 0%, #fceeae 16%, #e09e5b 36%, #fce9a4 70%, #f1d693 77%, #e1bd7c 88%, #d08a4a 100%);
  height: 100%;
  width: 100%;
  display: block;border-radius: 15px;
}

.feature-items ul li::after {
  background: var(--dark-color);
  z-index: 1;
  left: 2px;
  top: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  z-index: 1;border-radius: 15px;
}

.feature-items ul li .title{
  position: absolute;
  left: 50%;
  font-weight: 600;
  transform: translate(-50%);
  background-color: var(--dark-color);
  top: -1rem;
  padding: 0 10px;
  font-size: 1.2rem;
  z-index: 2;
}
.feature-items ul li .img{
  width: 94%;
  background-repeat: no-repeat;
  margin:1.5rem 3% 0rem;
  background-size: cover;
  position: relative;
  z-index: 2;
}
.feature-items ul li .txt{
  padding: 1.5rem ;
  position: relative;
  z-index: 2;text-align: center;
 
}

@media (min-width: 768px) {
  .feature-items ul li {
    flex: 0 0 46%;}
}


@media (min-width: 992px) {
  .home-features{
    padding: 2rem 0 1rem;
    background: url(../images/join_bg.png) no-repeat top center/cover;
  }
  .feature-items ul li {
    flex: 0 0 29.33%;
    margin:1rem 1.5% 3rem;
  }
  .feature-items ul li .title{
    font-size: 1.5rem;
  }

}

/* 路線圖 */
.home-map{
  background: url(../images/map_bg.png) no-repeat top center/cover;
  width: 100%;
  padding: 2rem 0; 
}
.map-items ul.items-box{position: relative;display: flex;flex-wrap: wrap;}
.map-items ul.items-box::after {
  content: '';
  height: 80%;
  width: 1px;
  background-color: var(--main-color);
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translate(-50%);
}
.map-items ul.items-box li{color: var(--sub-color); position: relative;flex:0 0 100%;text-align: center;margin-bottom: 3rem; align-self: center;}

.map-items ul.items-box li:before,
.map-items ul.items-box li:after {
  content: '';
  position: absolute;
}
.map-items ul.items-box li::before {
  background: linear-gradient(to right, #bb7239 0%, #fceeae 16%, #e09e5b 36%, #fce9a4 70%, #f1d693 77%, #e1bd7c 88%, #d08a4a 100%);
  height: 100%;
  width: 100%;
  display: block;
  z-index: 1;
}

.map-items ul.items-box li::after {
  background: var(--dark-color);
  left: 2px;
  top: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  z-index: 2;
}
.map-items ul.items-box li .border {
  border: var(--main-color) 1px solid ;
  background: url(../images/map_bg.png) center   /cover;  
  display: block;
  position: relative;
  margin:6px ;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  z-index: 3;
}
.map-items ul.items-box li:last-child .border{
  background: url(../images/map_bg.png) center bottom /cover;  
}
.map-items ul.items-box li:last-child .border::before {
  display: none;
}



.map-items ul.items-box li .border span {
  background: url(../images/map_border.png) no-repeat;
  background-position: bottom left;
  position: absolute;
  width: 24px;
  height: 24px;
  left: -7px;
  bottom: -7px;
}
.map-items ul.items-box li .border span:nth-child(2) {
  background-position: bottom right;
  transform: rotate(270deg);
  left: calc(100% - 17px);
}
.map-items ul.items-box li .border span:nth-child(3) {
  background-position: bottom right;
  transform: rotate(180deg);
  left: calc(100% - 17px);
  bottom: calc(100% - 17px);
}
.map-items ul.items-box li .border span:nth-child(4) {
   background-position: bottom left;
   transform: rotate(-270deg);
   left: -7px;
   bottom: calc(100% - 17px);
 }
.map-items ul.items-box li .border .title {
    font-size: 1.5rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    background-color: var(--dark-color);
    top: -1rem;
    padding: 0 10px;
}
.map-items ul.items-box li .border .txt {
  margin:1.5rem 1rem 1rem;
  position: relative;
}


.map-items ul.items-box li .border .txt .list h4{
  /* font-size: 1.1rem; */margin-bottom: 5px;font-weight: 400;
}
.map-items ul.items-box li .border .txt .list div{
  padding: 5px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: auto;
}

.map-items ul.items-box li .border .txt .list div p{flex:0 0 50%;text-align: left;padding: 5px;}

@media (min-width: 992px) {
  .home-map {
    padding: 4rem 0;
  }
  .map-items ul.items-box::after {
    
    height: 68%;
    width: 1px;
    top: 16%;
  }
  .map-items ul.items-box li {
    flex:0 0 42%;
    width: 42%;
    margin: 0rem 4% 4rem;
  }
  .map-items ul.items-box li:nth-child(2n) {
    margin: 10rem auto 0rem;
    
  }
 
  .map-items ul.items-box li .border .line{
    content: '';
    position: absolute;
    width: 40px;
    right: -45px;
    top: 50%;
    margin-left: -3%;
    bottom: -26%;
    height: 1px;
    background-color: var(--sub-color);
  }
  .map-items ul.items-box li:nth-child(2n) .border .line {
    right: auto;
    left: -30px;
    transform: rotate(0);
    bottom: auto;
  }

.map-items ul.items-box li:last-child .border::before {
  display: block;
}
  .map-items ul.items-box li:nth-child(2n) .border .line::after {
    right: 0;
    left: -12px;
  }

  .map-items ul.items-box li:nth-child(2n) .border .line::before {
    right: auto;
    left: -20px;
  }
  .map-items ul.items-box li .border .line::before,
  .map-items ul.items-box li .border .line::after {
    content: '';
    position: absolute;
    border-radius: 50%;
  }

  .map-items ul.items-box li .border .line::after {
    width: 24px;
    height: 24px;
    background-color: var(--main-color);
    top: -12px;
    right: -12px;
  }

  .map-items ul.items-box li .border .line::before {
    width: 40px;
    height: 40px;
    right: -20px;
    background-color: var(--dark-color);
    top: -20px;
    border: 2px solid var(--main-color);

  }
  .map-items ul.items-box li .border .txt .list{
    padding: 0 1.5rem;
  }
  .map-items ul.items-box li .border .txt .list div{width: 360px;}
  
}
@media (min-width: 1200px) {
  .map-items ul.items-box li {
    flex: 0 0 44%;
    width: 44%;
    margin: 0rem 3% 4rem;
  }
}
/* 合作夥伴及投資者 */
.home-team{padding: 0 0 2rem;}
.team-title:after{
  background-color:var(--dark-color);
}
.team-title:before{
  content: '';
  width: 100%;
  height: 1px;
  bottom: 7px;
  left: 0;
  position: absolute;
  background-color: var(--main-color);
}
.partner-items{display: flex;flex-wrap: wrap;justify-content: center; padding: 0 15px;margin-top: 3rem;}
.partner-items li {
  color: var(--sub-color);
  flex: 0 0 46%;
  height: 100%;
  background-color: var(--white-color);
  border-radius: 50%;
  margin: 0 2% 2rem;
  padding: 1rem;
  text-align: center;
  position: relative;
}
.partner-items li .img{
  padding-bottom: 100%;
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.partner-items li .img img{
  position: absolute;
  height: 100%;
  border-radius: 1rem;
  display: block;
  width: 100%;
  left: 0;
  top: 0;
}

@media (min-width: 992px) {
 
  .partner-items li{
    flex:0 0 31%;
    margin: 0 1% 2rem;
  }
}
@media (min-width: 1200px) {
  .partner-items li {
    flex: 0 0 23%;
   }
}
@media (min-width: 1400px) {
   .partner-items li {
     flex: 0 0 14.66%;
   }
}

/* 遊戲開始 */
/* 遊戲選單 */
.game-box {
  background: url(../images/story_bg.png) no-repeat top center/cover;
  padding: 7rem 15px 4rem;
}
.game-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  color: var(--main-color);
  font-weight: 400;
  width: 100%;
  height: 40px;
  position: relative;
  z-index: 2;
  line-height: 40px;
  border: 1px solid #666977;
  border-radius: 5px;
  cursor: pointer;
}

.game-btn svg {
  color: var(--main-color);
}

@media (min-width: 992px) {
  .game-btn {
    display: none;
  }
}
.game-box .game-nav {
  border-radius: 5px;
  border:1px solid #666977;
  background-color: var(--dark-color);
  position: relative;
  display: none;
  opacity: 0;
  width: calc(100% - 4rem );
  transition: all 1s ease-in;
}
.game-box .game-nav.active{
  display: block;
  opacity: 1;
  
  position: absolute;
  z-index: 21;
}

.game-box .game-nav:before,
.game-box .game-nav:after {
  content: '';
  position: absolute;
  
}
.game-box .game-nav:before{
  background: url(../images/light5.png)no-repeat;
  width: 300px;
  margin-left: -150px;
  height: 41px;
  background-size: contain;
  left: 50%;
  top: -15.5px;
}
.game-box .game-nav:after {
  background: url(../images/light4.png)no-repeat;
  width: 184px;
  margin-left: -92px;
  height: 44px;
  background-size: contain;
  left: 50%;
  bottom: -17px;
  opacity: .8;
}

.game-nav ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  z-index: 21;
}
.game-box .game-nav li a {
  color: var(--white-color);
  padding: .5rem 1.5rem;
  display: block;
  margin: 0 5px;
  border-radius: 10px;
}
.game-box .game-nav li a:hover{
  background: linear-gradient(to bottom, #FE844E 0%, #FFC943 100%);
  color: var(--white-color);
  font-weight: 300;
}
.game-box .game-nav li.active a{
  display: none;
}
.game-box .game-nav li.active a:hover {
  background: linear-gradient(to bottom, hsla(43, 100%, 63%, 0.8) 0%, hsla(18, 99%, 65%, 0.8) 100%);
}

@media (max-width: 991px) {
  .game-box .game-nav li {
    flex:0 0 100%;
  }
  
}
@media (min-width: 992px) {
  .game-box .game-nav {
    display: block;opacity: 1;
  }
  .game-box .game-nav.active{position: relative;}
  .game-box .game-nav li.active a {
    display: block;
    background: linear-gradient(to bottom, #FE844E 0%, #FFC943 100%);
    color: var(--white-color);
    text-shadow: 0 5px 15px rgb(0 0 0 / 35%);
    transition: all 1s ease;
  }
}
.game-content-head{
  padding-top: 2.5rem;margin-bottom: 2.5rem;position: relative;
}
.game-content-head .title{ font-size: 1.6rem; color: var(--yellow-color);text-align: center;}
.game-content-head .time-box{margin-top: 1rem;}
.game-content-head .name{position: relative;text-align:right;right: 0;top: 0; color: var(--sub-color);margin-bottom: 5px;}
.game-content-head .name .label-icon{width: 60px;display: inline-block;}
.check-milt{display: flex;flex-wrap: wrap;justify-content: space-around;}
.check-milt .item-milt{flex:0 0 100%;margin: 0 2% 1.5rem;}
.check-milt .item-milt .btn-box{display: flex;justify-content: center;}
.check-milt .item-milt .btn-box .btn{
  width: 140px;
  line-height: 44px;
  padding: 0;font-size: 1rem;
  margin: 0 5px;
  cursor: pointer;
  font-weight: 600;border:none;
 
}
.check-milt .item-milt .title {
  font-size: 1.5rem;
  color: var(--yellow-color);
  text-align: center;
  font-weight: 600;
  margin-bottom: 1rem;
  padding-bottom: 1.25rem;
  background: url(../images/characters-border.png) no-repeat bottom center;
}
.check-milt .item-milt .text p {
  color: var(--white-color);
  text-align: center;
  margin-bottom: .5rem;
}
.check-milt .item-milt .text p span{font-size: 1.25rem; font-weight: 600; color: var(--yellow-color);}
.check-milt .img{
  padding-bottom: calc(100% - 4px);
  border-radius: 1rem;
  margin: 0 0 1rem;
  background-size: cover;
  border: 2px solid var(--sub-color);
  position: relative;
  overflow: hidden;
}
.check-milt .img span{
  position: absolute;
  height:100%;
  display: block;
  width: 100%;
  left: 0;
  top: 0;
  
}
.check-milt .milt-character .img span {
  opacity: 0;border-radius: 1rem;
  filter: alpha(opacity=0);
  -webkit-animation: round 5s linear infinite;
  animation: round 5s linear infinite;
}
.check-milt .item-milt .img:hover span {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.check-milt .milt-character .img span:nth-child(2) {
  animation-delay: 1s;
}

.check-milt .milt-character .img span:nth-child(3) {
  animation-delay: 2s;
}

.check-milt .milt-character .img span:nth-child(4) {
  animation-delay: 3s;
}

.check-milt .milt-character .img span:last-child {
  animation-delay: 4s;
}

.check-milt .milt-pet .img{position: relative;}
.check-milt .milt-pet .img:after{
  content:'';
  background: url(../images/egg-shodow.png) no-repeat top center /cover;
  max-width: 300px;
  width: 100%;
  height: 60px;
  position: absolute;
  left: 0;bottom:  0;
}
.check-milt .milt-pet .img span {
  position: absolute;
  z-index: 1;
  transform: rotate(0) ;
  transform-style: preserve-3d;
  transform-origin: bottom center;
  -webkit-animation: round2 .8s linear infinite;
  animation: round2 .8s linear infinite;
  
}




@keyframes round2 {
  6.66% {
    transform: rotate(8deg) ;
  }

  33.3% {
    transform: rotate(0deg) ;
  }

  39.6% {
    transform:rotate(-8deg) ;
  }

}
@media (min-width: 992px) {
  .check-milt .item-milt {
    flex: 0 0 300px;
  }
  .game-content-head{    
    padding-top: 3rem;
    margin-top: 2rem;
  }
  .game-content-head .name {
    position: absolute;}
}

  .copy-code{display: flex;justify-content: space-between;margin-top: 2rem;margin-left: -15px; margin-right: -15px;border: 1px solid #8e8e8e;border-radius: 5px;}
  .copy-code .form-control{background-color: transparent;flex:0  0 calc(100% - 110px);width: calc(100% - 110px);border:none;border-radius: 0; padding:.5rem 1rem;font-size: 1.125rem;color: var(--white-color);font-weight: 400;}
  .copy-code .form-control:focus{border:none;outline: none;}
  .copy-code .copy{ display: block;padding: .6rem;border: none;font-size: 1rem; cursor: pointer; background: transparent; flex:0 0 40px;width: 40px;}
  .copy-code .yellow-btn{font-size: 1rem;border: none; border-radius: 0 5px 5px 0;}



.pay-content{position: relative;overflow: hidden;width: 100%;height: 100%;}
.pay-content img{position: absolute;right: -100%;top: 0;z-index: 1000;transition: all ease-in .8s;}
.pay-content.check img{right: 0;position: fixed; }
/* 獲得NFT */
/* 我的NFT */
.nft-search-box{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;color: #fff;    
  position: relative;
  top: 0;
}  
.nft-search-box .text{width: 100%;margin-bottom: 0.5rem;margin-top: 0.5rem;}
.nft-search-box .select a{cursor: pointer; margin: 0  5px; background-color:#08061F ;color: var(--yellow-color);  border:2px solid #454545; border-radius: 25px;padding:5px .6rem; display: flex;align-items: center;justify-content: space-between;} 
.nft-search-box .select{flex:0 0 50%; width: 50%;}
.search-items{ display: none;}
.search-items ul{ border-radius: 5px; border:1px solid #454545;margin: 0 5px;position: absolute;background-color:#08061F ;width: 50%;}
.search-items ul li{color: var(--yellow-color);padding: 8px 1rem; cursor: pointer;}
.search-items ul li:hover{ background-color:#38374b;}
.search-items.check{display: block;}
.pledge-items ul{display: flex;justify-content: space-between;flex-wrap: wrap;   margin:0 auto }
.pledge-items ul li{flex:0 0 97%; margin:  1.5%;}
.pledge-items ul li a{margin:0 auto 1rem;cursor: pointer;}
.pledge-items ul li a.disabled{ opacity: 0.65;pointer-events: none; }
.pledge-items ul li .img{position: relative;border-radius: 1rem;margin:0 auto 1rem;display: block;overflow: hidden;}
.pledge-items ul li img{border-radius: 1rem;border: 2px solid var(--sub-color);width: 100%;}
.pledge-items ul li .img .sword{ position: absolute; right: 1rem;top:0.75rem;transform: rotate(180deg);}
.pledge-items ul li .img .sword img{border:none}
@media (min-width: 992px) {
  .nft-search-box{ top: 3.5rem;}
  .nft-search-box .text{width: auto;display: inline; margin-right: 0.5rem;margin-bottom:0;}
  .nft-search-box .select{flex:0 0 250px;}
  .search-items ul{width: 240px;}
  .pledge-items ul{max-width: 900px;    margin: 5rem auto 0;}
  .pledge-items ul li{flex:0 0 30.33%; margin:  1.5%;}
  .pledge-items ul li a{max-width:140px;}
}
@media (min-width: 1200px) {
  .pledge-items ul{max-width: 1200px;}
}



/* 眾籌遊戲 */
.bid-head{margin-top: -1.5rem;}
.bid-head .inner{
  position: relative;
  background: linear-gradient(to right, #bb7239 0%, #fceeae 16%, #e09e5b 36%, #fce9a4 70%, #f1d693 77%, #e1bd7c 88%, #d08a4a 100%);
  border-radius: 15px;
  min-height: 180px;
  width: 100%;
}
.bid-head .inner:after{
    content: '';
    background: var(--dark-color);
    z-index: 1;
    width: calc(100% - 4px );
    height: calc(100% - 4px );
    display: block;
    margin: 2px;
    border-radius: 15px;
    position: absolute;
    left: 0;
    top: 0;
}
.bid-head .text-box{ width: 100%; margin-bottom: 2rem; padding:2rem 1.5rem; position: relative;z-index: 2;}
.bid-head .text-box .bid-text{display: flex;flex-wrap: wrap;}
.bid-head  .title{
    font-size: 2rem;
    color: var(--yellow-color);
    text-align: center;
    font-weight: 600;
    margin-bottom: -.5rem;
    padding-bottom: 1.25rem;
    background: url(../images/characters-border.png) no-repeat bottom center;
    position: relative;
    z-index: 3;
   
}
.bid-head .text-box .title,.bid-head .text-box .text{flex:0 0 100%;}
.bid-head .text-box .text{ color: var(--yellow-color);font-family: 'Cairo' , Arial, 'Noto Sans TC'; font-size: 1.25rem; font-weight: 600;}

.bid-head .text-box .text .item{display: flex;flex-wrap: wrap; justify-content: space-between;align-items: center; margin-bottom: 0.5rem;padding: 5px;}
.bid-head .text-box .text .item:nth-child(odd){ background-color: rgba(255, 255, 255, 0.2)} 
.bid-head .text-box .text .item .name{ color: var(--main-color); font-size: 1.1rem; font-weight: 400;}
.bid-head .text-box .text .input_dollar {margin: 1rem 0; display: flex; flex-wrap: wrap; justify-content: space-between; align-items: flex-start;}
.bid-head .text-box .text .input_dollar .name{font-weight: 600;color: var(--white-color);font-size: 18px; flex: 0 0 85px;display: flex;align-items: center; width: 85px;margin-right: 5px;}
.bid-head .text-box .text .input_dollar .name i{width: 40px;margin-right: 5px;}
.bid-head .text-box .text .btn{ font-size: 1rem; font-family: 'Noto Sans TC', Arial, sans-serif; border: none;flex:0 0 100%; width: 100%; }
.bid-head .text-box .text .input{flex:0 0 calc(100% - 95px);width: calc(100% - 95px);margin-bottom: 5px; margin-right: 5px;}
.bid-head .text-box .text .item i{width: 28px;display: inline-block;margin-right: 10px;}
.bid-head .text-box .text .input_dollar .input-num{    
  background-color: var(--dark-color);padding:0 8px;    
  border: 1px solid #8e8e8e; color: var(--yellow-color);height: 40px;
  border-radius: 5px;
  width: 100%;
  font-size: 1.5rem;font-weight: 600;
  margin-right: 8px;font-family: 'Cairo' , Arial, 'Noto Sans TC';
}
.bid-head .text-box .text .input .note{text-align: right; font-size: 15px;width: 100%; flex:0 0 100%; color: var(--main-color);margin-top: 5px;font-weight: 400;}
.bid-head .pie_Chart_box{flex:0 0 98%;width: 98%;margin: 0 auto ;}
.bid-head .pie_Chart{width: 100%;min-height:300px;margin: auto;}
.bid-head  .time-box{position:relative; z-index:5; background-color: var(--dark-color);    margin-top: -3%;}
.bid-table{ 
  width: 100%; 
  margin-top: -2.5%; 
  color: var(--yellow-color);
  position: relative;
  z-index: 5; 
  border-spacing: 0; 
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  border-bottom: 1px solid var(--yellow-color);
}
.bid-table thead{    
  display: flex;
  flex-shrink: 0;
  min-width: min-content;
}
.bid-table  tbody {
  display: flex;
  position: relative;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  
}

.bid-table tr{
  display: flex;
  flex-direction: column;
  min-width: min-content;
  flex: 1 0 auto;
  
}


.bid-table td,
.bid-table th {
  display: block;font-weight: 400;text-align: center;
  padding:5px;
  font-family: 'Cairo' , Arial, 'Noto Sans TC';
  border-top:1px solid var(--yellow-color);
}

.bid-table th{ width: 60px;}
.bid-table .price{ color:var(--white-color);margin-bottom: 5px;}
.pie_Chart_box .total{display: flex;flex-wrap: wrap; justify-content: space-between;align-items: center;  color: var(--yellow-color);}
.pie_Chart_box .total .price{font-size: 1.8rem; font-weight: 600;}
.bid-table tbody::-webkit-scrollbar{width: 12px;height: 6px;position: absolute;  }
.bid-table tbody::-webkit-scrollbar-track{background-color:transparent;}
.bid-table tbody::-webkit-scrollbar-thumb{background: linear-gradient(to right, #bb7239 0%, #fceeae 16%, #e09e5b 36%, #fce9a4 70%, #f1d693 77%, #e1bd7c 88%, #d08a4a 100%);border-radius: 5px; }

/* 過去重酬清單 */
.bid-items{
  position: relative;
  background: linear-gradient(to right, #bb7239 0%, #fceeae 16%, #e09e5b 36%, #fce9a4 70%, #f1d693 77%, #e1bd7c 88%, #d08a4a 100%);
  border-radius: 15px;
  margin-top: 2rem;
  overflow: hidden;
}
.bid-items:after{
  content: '';
  background: var(--dark-color);
  z-index: 1;
  width: calc(100% - 4px );
  height: calc(100% - 4px );
  display: block;
  margin: 2px;
  border-radius: 15px;
  position: absolute;
  left: 0;
  top: 0;
}
.bid-items-table{width: 100%; color: var(--white-color);position: relative;z-index: 2;border-spacing: 0; }
.bid-items-table thead tr th{ border-bottom: 1px solid var(--main-color);}
.bid-items-table th{font-weight: 400;text-align: left;color: var(--main-color);}
.bid-items-table th,.bid-items-table td{padding: .8rem 2rem;}
.bid-items-table tbody tr:nth-child(odd){background-color: rgba(255, 255, 255, 0.2);}
.bid-items-table thead th:last-child{width: 20%;}
.bid-items-table td span{ color: #a5a5a5; font-size: 14px;margin-right: 5px; font-weight: 300;}
.bid-items-table .maxbtn{font-size: 15px;}
.bid-items-table .maxbtn.disabled:disabled {
 opacity: .6;
}
.bid-items-table .maxbtn.disabled:disabled:hover{box-shadow: none;}
@media (min-width: 992px) {
  
  .bid-head .text-box .text{flex:0 0 calc(100% - 42%);align-self: center; padding: 0 2% 0 0; font-size: 1.5rem;}
  .bid-head .pie_Chart_box{flex:0 0 42%; width: 42%;}
  .bid-head .pie_Chart{width: 100%;height: 280px;}
  .bid-head .text-box .text .btn{ flex:0 0 40%; width: 40%;}
  .bid-head .text-box .text .input{flex:0 0 calc(100% - 40% - 95px)}
  .bid-items ul{margin:3rem 0 0;}
  .bid-items ul li{flex:0 0 46%;width: 46%;margin:1rem 2% ; }
  .bid-items ul li .inner .text{flex:0 0 50%;border-right:1px solid var(--main-color);border-bottom:none;}
  .bid-items ul li .inner .button{width: calc(50% - 30px);}
  
}
@media (min-width: 1200px){
  .bid-head .time-box{    margin-top: -4%;}
  .bid-table thead tr{margin-bottom: 0;}
 
}
@media (max-width: 991px){
  /* .bid-items-table thead{display: none;}
  .bid-items-table td[data-th]:before {
    content       : attr(data-th);
    display       : inline-block;
    color         : var(--main-color);
    width         : 80px;
    text-transform: uppercase;
    margin-right  : .5rem;
  }
  .bid-items-table td {
    text-align: left;
    overflow: hidden;
    width: 100%;
    padding: .6rem 2rem;
    display: block;
  } */

  .bid-items-table th,.bid-items-table td{padding: .8rem .4rem;}
  .bid-items-table td {

    padding: .6rem .4rem;

  } 
}


/* 競技場 */
.arena_box .item{margin-bottom: 1rem;}
.arena_box .item img{width: 100%;}
.arena_box .img{ width: 100%; display: none;}
.arena_box .img-m{  width: 100%; display: block;}
@media (min-width: 992px){
  .arena_box .img-m{ display: none;}
  .arena_box .img{ display: block;}
}
/* 排行榜 */
/* 紅利 */
.dividend{margin-top: -1.5rem;}
.dividend .inner {display: flex;flex-wrap: wrap; align-items: center;  color: var(--main-color);}
.dividend .item{display: flex;flex-wrap: wrap;flex:1 0 100%; align-items: center; padding:2rem 1rem 0; position: relative;z-index: 10; }
.dividend .item ~ .item{   padding: 0 1rem 2rem;}
.dividend-content{width: 100%; align-items: center;display: flex;flex-wrap: wrap;}
.dividend .item .num{color: var(--yellow-color);   padding: 0.5rem 5px; font-family: 'Cairo' , Arial, 'Noto Sans TC'; font-weight: 700;font-size: 1.8rem;}
.dividend  .dollor{ color: var(--white-color);}
.dividend .item .name{min-width: 80px;font-size: 18px;  font-weight: 700;  font-family: 'Cairo' ,Arial;margin: 3px 0;}
.dividend .item .form_box{border: 1px solid #8e8e8e; border-radius: 5px 0 0 5px;background-color: var(--dark-color); padding:0 5px; display: flex;
  align-items: center; justify-content: space-between; width: calc(100% - 70px);}
.dividend .item .input-num{font-family: 'Cairo' , 'Arial'; color: var(--yellow-color); font-size: 1.8rem;font-weight: 700;height: 45px;line-height: 45px;
  border-radius:0;background-color: var(--dark-color);padding:0 .5rem;    width: 80%;flex:0 0 80%;
  border:none;border-radius: 0 0 0 5px;
}
.dividend .item .input-num:focus{ outline: none;}
.dividend .item .yellow-btn{border-radius: 0 5px 5px 0;  border: 1px solid #FFC943;font-size: 1rem;cursor: pointer;     padding:0 1rem;height: 45px;line-height: 45px;}
.dividend-content .title{width: 100%; color: var(--main-color);font-size: 1.4rem;text-align: center;margin-bottom: 1rem;}
.dividend-content .item{margin-top: 2rem;margin-bottom: 1rem;    flex: 0 0 100%;  width: 100%;}
.dividend-content .item:nth-child(2) .inner td,.dividend-content .item:nth-child(2) .inner th{padding:0 .5rem;}
.dividend .inner,.dividend-content .inner{
  position: relative;
  background: linear-gradient(to right, #bb7239 0%, #fceeae 16%, #e09e5b 36%, #fce9a4 70%, #f1d693 77%, #e1bd7c 88%, #d08a4a 100%);
  border-radius: 15px;min-height: 180px;
}
.dividend .inner:after,.dividend-content .inner:after{
  content:''; 
  background: var(--dark-color);
  z-index: 1;
  width: calc(100% - 4px );
  height: calc(100% - 4px );
  display: block;margin:2px;
  border-radius: 15px;
  position: absolute; 
  left: 0;top: 0;
}
 .dividend-content .inner .rtable{
  position: relative;z-index: 2; 
  color: var(--white-color);
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
}

.rtable-flip thead {
  display: flex;
  flex-shrink: 0;
  min-width: min-content;
  color: var(--main-color);
}
.rtable-flip tbody {
  display: flex;
  position: relative;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  
}
.rtable th {
  text-align: left;
  padding: 0 1rem;
}
.rtable th:first-child,.rtable td:first-child{border-bottom: 1px solid var(--main-color);} 
.rtable td {
  padding: 0 1rem;
  text-align: right;
}

.rtable-flip tr {
  display: flex;
  flex-direction: column;
  min-width: min-content;
  flex:1 0 auto;
}
.rtable-flip td,
.rtable-flip th {
  display: block;font-weight: 400;
  height: 45px;line-height: 45px;
  font-family: 'Cairo' , Arial, 'Noto Sans TC';
}
.rtable td:first-child{
  color: var(--main-color);font-weight: 400;
}
.rtable tbody::-webkit-scrollbar{width: 12px;height: 10px; }
.rtable tbody::-webkit-scrollbar-track{background-color:transparent;}
.rtable tbody::-webkit-scrollbar-thumb{background: linear-gradient(to right, #bb7239 0%, #fceeae 16%, #e09e5b 36%, #fce9a4 70%, #f1d693 77%, #e1bd7c 88%, #d08a4a 100%);border-radius: 5px; margin-bottom: 10px;}

.dividend-content .share{
  position: relative;z-index: 2; 
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;align-items: center; 
}
.dividend-content .share-name{color:#7C7C7C;}
.dividend-content .share ul{display: flex;justify-content: center;align-items: center;}
.dividend-content .share ul li a{display: block;}
.dividend-content .share ul li a:hover svg{ color: var(--yellow-color);}
.dividend-content .share ul li a svg{ 
  width: 46px;
  color:#7C7C7C;}
.dividend-content .copy-code{margin: 0; width: 100%;}
.dividend-content .copy-code .form-control{flex: 0 0 calc(100% - 40px);
  width: calc(100% - 40px);}
@media (min-width: 414px){
  .dividend .item .input-num{width: 80%; flex:0 0 80%}
}
/* @media (min-width: 1200px) {
  .dividend .item .form_box{width: calc(100% - 70px - 110px);}
} */
/* @media (min-width: 992px) {
  .dividend{justify-content: center; margin: 0 1.5% ;}
  .dividend .item{flex:1 0 50%; align-content: flex-start;height: 100%;}
  .dividend .item .form_box{width: calc(100% - 70px - 110px);}
  .dividend .item~.item{justify-content: flex-start;    padding: 1rem 1.5rem;}
  .dividend .item .name{width:auto;font-size: 21px;} 
  .dividend .item .yellow-btn{width: 70px ;}
  .dividend-content .item{flex:1 0 54%; margin:2rem 1.5% 1rem;} 
  .dividend-content .item~.item{ flex:1 0 40%;align-self: flex-end;}
  .dividend-content .item:nth-child(2) .inner td,.dividend-content .item:nth-child(2) .inner th{padding:0 1.5rem;}
  .dividend-content .item:last-child{flex:1 0 97%;}
   
} */

@media (min-width: 1200px) {
  .dividend .item .form_box{width: calc(100% - 70px - 180px);}
}





/* $PACO */
.game-content-body .border{
  position: relative;
}
.game-content-body .border::before,.game-content-body .border::after{
 content: '';
 position: absolute;
}
.game-content-body .border::before {
 background: linear-gradient(to right, #bb7239 0%, #fceeae 16%, #e09e5b 36%, #fce9a4 70%, #f1d693 77%, #e1bd7c 88%, #d08a4a 100%);
 height: 100%;
 width: 100%;
 display: block;border-radius: 15px;
}

.game-content-body .border::after {
 background: var(--dark-color);
 z-index: 1;
 left: 2px;
 top: 2px;
 width: calc(100% - 4px);
 height: calc(100% - 4px);
 z-index: 1;border-radius: 15px;
}
.game-content-body .text{display: flex;align-items: center;justify-content: center;margin-bottom: 2rem;margin-top: 4rem;}
.game-content-body .text i{width: 40px; display: inline-block; margin-right: 1rem; }
.game-content-body .text .title{color: var(--yellow-color);text-align: left;}
.game-content-body .text .title span{display: block;color: var(--white-color);font-weight: 300;font-size: 1rem;}
.swap,.stake{position: relative;z-index: 2;font-family: 'Cairo' , Arial, 'Noto Sans TC'; }
.swap{padding:2rem 1.5rem; display: flex; flex-wrap: wrap;align-items: flex-end; }
.swap .title{ margin:0 auto 1rem; color: var(--yellow-color); font-size: 1.5rem;background: url(../images/characters-border.png) no-repeat center bottom /contain;padding-bottom: 1rem;text-align: center; }
.swap .title .num{display: block; color: var(--white-color);}
.swap .itembox{ flex:0 0 100%;  display: flex; flex-wrap: wrap;}
.swap .itembox .item{ margin-bottom: 5px; color: var(--white-color);width: 100%;}
.swap .itembox .item .head {display: flex;justify-content: space-between;  align-items: center;font-weight: 500;font-size: 1.125rem; margin-bottom: .5rem;}
.swap .itembox .item .head .name{display: flex; align-items: center;}
.swap .itembox .item .head a{margin-left: 1rem; cursor: pointer;}
.swap .itembox .item .head a svg{width: 26px;}
.swap .itembox .item .name i{width: 32px; display: inline-block; margin-right: .5rem; }
.swap .itembox .link{width: 100%;text-align: right;margin: 5px;}
.swap .itembox .input-control{position: relative;height: 80px;}
.maxbtn {transition: all .5s ease-in; cursor: pointer; border-radius: 2rem; border: 1px solid var(--main-color); color: var(--main-color);padding: 5px 1rem; background-color: transparent;min-width: 80px; }
.maxbtn:hover{ box-shadow: 0 0 12px var(--yellow-color); }
.swap .itembox .maxbtn{position:absolute;bottom: .5rem;right: .5rem;}
.swap .itembox input{
 background-color:#262626 ; 
 border-radius: 5px; 
 border: none; 
 color: var(--white-color);
 font-size: 1.5rem;
 font-weight: 600;
 text-align: right;
 width: 100%;
 height: 100%;
 padding: 10px 1rem 40px 1rem ;
 position: absolute;
 top: 0;left: 0;
}
.swap .itembox input:focus{outline: none; }
.swap .itembox .change{
 cursor: pointer; 
 background: linear-gradient(to bottom, #FE844E 0%, #FFC943 100%); 
 border: 3px solid var(--yellow-color); color: var(--white-color); 
 border-radius: 50%; 
 width: 44px;
 height: 44px;
 padding:4px ; 
 text-align: center;
 transform: rotate(90deg);
 margin: 1rem auto;
 position: relative;
}
.swap .itembox .change svg{position: absolute;left: 50%;top:50%;transform: translate(-50% , -50%);transition: all .2s;}
.swap .itembox .change svg~svg{opacity: 0; width: 24px;}
.swap .itembox .change:hover svg{opacity: 0;}
.swap .itembox .change:hover svg~svg{ opacity: 1;}
.swap .btn-box{width: 100%;}
.swap .btn-box a{ cursor:pointer; margin-bottom: 5px;   flex: 0 0  100%; display: flex;justify-content: center; align-items: center;}
@media (min-width: 1200px) {
 .swap .title{flex:0 0 200px;  }
 .swap .itembox{flex:0 0 calc( 100% - 200px - 120px);align-items:flex-end; }
 .swap .itembox .item{flex: 0 0 43%;margin-left: 1%;margin-right: 1%;}
 .swap .itembox .link{width: auto;}
 .swap .btn-box{ flex:0 0 120px;}
 .swap .btn-box a{height: 80px;}
 .swap .itembox .change{transform: rotate(0); width: 36px;
   height: 36px;}
   
}
.stake{color: var(--white-color);}
.stake-head{display: flex;flex-wrap: wrap; border-bottom: 1px solid var(--yellow-color); padding:1rem 1.5rem;}
.stake-head .name{display: flex; align-items: center;flex:0 0 100%;margin-bottom: .5rem;}
.stake-head .name i{width: 45px;  display: inline-block;  margin-right: 1rem;}
.stake-head .name  .subtitle{color: var(--yellow-color);font-size: 1.5rem;font-size: 1rem;font-weight: 300;}
.stake-head .name  .subtitle span{color: var(--white-color);font-size: 1.6rem; font-weight: 600;margin-left: 15px;}
.stake-body{display: flex;flex-wrap: wrap; }
.stake-body .stake-items li.item{padding:1rem 1.5rem;}
.stake-body .stake-items li.item:not(:last-child){ border-bottom: 1px #797979 dashed;}
.stake-body .stake-items  .name{display: flex;flex-wrap: wrap;align-items: center;margin-bottom: .5rem;}
.stake-body .name p { color: var(--main-color);width: 120px;margin-bottom: 5px; font-size: 1rem;}
.stake-body .name .num{ font-weight: 400; font-size: 1.2rem;}
.stake-body .stake-items  .content{display: flex;flex-wrap: wrap;align-items: center;}
.stake-body .stake-items  .content .input-control{font-weight: 600; flex: 0 0 320px; margin-bottom: .5rem;}
.stake-body .stake-items  .content input{ width: 220px;margin-right: 5px; background-color:transparent; border: 1px solid #999; border-radius: 5px;padding: .5rem .8rem;color: var(--white-color);  font-size: 1.2rem;}
.stake-body .stake-items  .content .maxbtn{ margin-right: 30px;}
.stake-body .stake-items  .content a{width: 150px;}
.stake-body .interest{width: 98%; background-color: #333; border-radius:5px 5px 15px 15px;margin: 1%;padding: 1.5rem; display: flex; flex-wrap: wrap;align-content:space-between}
.stake-body .interest .name{flex:0 0 100%}
.stake-body .interest .btn-box{justify-content: flex-start;align-items: flex-end;}
.stake-body .interest .btn-box a{margin: 0 5px 0 0; flex:0 0 auto;}
.stake-body .interest .btn-box a.yellow-btn{width: 250px;} 
@media (min-width: 1200px) {
 .stake-head .name{flex:0 0 20%;margin-bottom:0;}
 .stake-head .name:first-child{flex:0 0 320px; width: 320px;}
 .stake-head .name:last-child{flex:0 0 calc(100% - 320px - 40%);}
 .stake-head .name .subtitle span{display: block;margin-left: 0;}
 .stake-body .stake-items{flex:0 0 63%;}
 .stake-body .stake-items  .name p{  flex: 0 0 320px;}
 .stake-body .interest{flex:0 0 35%;}
}

/* 加入建議代幣   */
.add-token{
  position: fixed; top: 0;right:-100%;width: 100%;
  height: 100vh;transition: all .5s ease-in;z-index: 125;
}
.add-token.check{
  position: fixed;
  z-index: 500;
  right: 0;
  display: flex;
  justify-content: flex-end;
}

.add-token .inner{ position: absolute; right: 0;z-index: 2;}
.pay-modal{
  position: absolute; top: -100vh;left: 0;width: 100%;
  height: 100vh;transition: all .5s ease-in;z-index: 125;
}  
.pay-modal.check{
  position: fixed;
  left:0;
  top: 0;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.pay-modal .modal-text p{margin-bottom: 1.5rem;}

/* 動畫 */
.animation{
  background: url(../images/animation-bg.png) no-repeat top center/cover;
  height: 100vh;
  overflow: hidden;
}
 .card-box {
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100%;
   overflow: hidden;
 }

 .toggle {
   width: 300px;
   height: 388px;
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
   animation: card 1s ease-in ;
   transform-origin: bottom center;
   transform: translateY(0);
 }
 @keyframes card {
  0% {
    bottom: -100%;
    opacity: .5;
    transform: translateY(100%);
  }
  50% {
    bottom: auto;
    transform: translateY(0);
    opacity: 1;
  }
  100% {   
    transform:  translateY(0);
    opacity: 1;
  }
 }
  .card-box.active .toggle{
    transform: rotate(0) rotateY(450deg) translateY(-200%);
    box-shadow:0px 0px 25px rgb(255, 225, 57) , 0px 0px 55px rgb(255, 225, 57);
    animation: card-animation 2s ease-in;
    transform-style: preserve-3d;
    transition:5s all ease;
    z-index: 10;
  }
 

  @keyframes card-animation {
    0% {
      transform: rotate(0) rotateY(0) translateY(0);
      box-shadow: 0 0 5px rgb(255, 225, 57);
      z-index: -1;
      opacity: 0;
    }
    85% {
      transform: rotate(0) rotateY(0) translateY(-40px);
      box-shadow: 0px 0px 25px rgb(255, 225, 57), 0px 0px 55px rgb(255, 225, 57);
      z-index: 10;
      opacity: 1;
    }
    100% {
      transform: rotate(0) rotateY(450deg) translateY(-40px);
      box-shadow: 0px 0px 25px rgb(255, 225, 57), 0px 0px 55px rgb(255, 225, 57);
      z-index: 10;
    }

  }


  .card-box.active .toggle.change {
   
    transform: rotateY(180deg) translateY(-40px) ;

  }

 
 

  .card-box .toggle .front-face,
  .card-box .toggle .back-face {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 0;
    backface-visibility: hidden;
  }

  .card-box .toggle .front-face {
    background: url(../images/card.png) no-repeat top center/cover;

  }

  .card-box .toggle .back-face {
    background: #fff;
    transform: rotateY(180deg);
  }
  


    
  .card-box ul {
    display: block;
    transform-origin: bottom center;
    width: 300px;
    height: 388px;
    position: absolute;
    animation: cardbox 1.1s ease-in;
    opacity: 1;
    z-index: 2;
    transform:  translateY(0) rotate(-8deg);
  }
 
  @keyframes cardbox {
    0% {
      transform:  translateY(100%) rotate(0);
      opacity: 0;
    }

    50% {
      transform:  translateY(0) rotate(0);
      opacity: 1;
    }
    


    90% {
      transform:  translateY(0) rotate(-8deg);
      opacity: 1;
    }

  }

  .card-box ul~ul {
    animation: cardbox2 1.1s ease-in;
    opacity: 1;
    z-index:1;
    transform:  translateY(0) rotate(8deg);
  }

  @keyframes cardbox2 {
    0% {
      transform:  translateY(100%) rotate(0);
      opacity: 0;
    }

    50% {
      transform:  translateY(0) rotate(0);
      opacity: 1;
    }

    90% {
      transform:  translateY(0) rotate(8deg);
      opacity: 1;
    }

  }
    
  .card-box ul li {
    position: absolute;
    left: 0;
    transition: all .1s;
    transform-origin: bottom center;
    transition-delay: calc(0.1s * var(--i));
    transform: rotate(0) translate(0);
  }

  .card-box.active ul li {
    transform: rotate(calc(-65deg / 5 * var(--i))) translate(0);
   
  }
  .card-box.active ul~ul li {
    transform: rotate(calc(65deg / 5 * var(--i))) translate(0);
  
  }
  .card-box.active ul li:first-child{z-index: 5;}
  .card-box.active ul li:nth-child(2){z-index: 4;}
  .card-box.active ul li:nth-child(3){z-index: 3;}
  .card-box.active ul li:nth-child(4){z-index: 2;}
  .card-box.active ul li:nth-child(5){z-index: 1;}
  .card-box li a {
    background:url(../images/card.png) no-repeat top center/cover;
    display: block;
    border-radius: 5px;
    width: 300px;
    height: 388px;
  } 
  .animation-modal{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -100vh;
    width: 100%;
    height: 100vh;
    opacity: 0;
    z-index: 100;
    transition: all .75s ease;
    background: url(../images/animation-bg2.png) no-repeat bottom center/cover;
  }

  .animation-modal.check {
    top: auto;
    opacity: 1;
   
    
    
  }
  .animation-modal .modal-content{
   
    transition: all .5s ease-in;
    position: absolute;
    transform: translateY(-100%);
    width: 96%;
    opacity: 0;
    z-index: 100;
    
  }
  .animation-modal.check .modal-content{
    
    transform: translateY(0);
    opacity:1;
    background-color: hsl(0, 0%, 0%,.8);
    border:2px solid var(--main-color);

  }
  
  .modal-content .border {
    position: relative;
    width: 100%;
    
    padding: .5rem 0 2.5rem;
    z-index: 102;
    
  }
  .modal-content .border::before,
  .modal-content .border::after {
    content: '';
    background: url(../images/join_border.png) no-repeat;
    background-size: contain;
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    z-index: 102;
  }
  .modal-content .border::before {
    left: 0;
    top: 0;
  }
  .modal-content .border::after {
    right: 0;
    bottom: 0;
    transform: rotate(-180deg);
  }
  .modal-content .close{display: flex;justify-content: flex-end;}
  .modal-content .close svg {
    color: var(--main-color);
    
  }
  .modal-content .content-text{height: 60vh;overflow-y: auto;}
  .modal-content p{
    color: #F7EAAD;
    text-align: center;margin-bottom: 1rem;
  }
  .modal-content p a{
    color: #F7EAAD;
  }
  .modal-content .img-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin: 1.5rem auto;
  }
  .modal-content .img-items div {
    flex:0 0 180px;
    width: 180px;
    height: 180px;
    margin: 0 10px 1rem;
    border-radius: 15px;
    border:2px solid var(--sub-color);
    background-size: cover;
    display: block;
    overflow: hidden;
    background-repeat: no-repeat;
  }
  .btn-box {
    display: flex;
    justify-content: center;
  }
  .btn-box a{flex:0 0 120px; margin: 0 15px; cursor:pointer;}
  
  
@media (min-width: 992px){
  .animation-modal .modal-content {
    width: 860px;
  }
  .animation-modal .modal-content .border{
    height: 100%;
  }
}

/* coming Soon */
.comingSoon{ background: url(../images/bg5.png) center /cover; }
.comingSoon .game-content{ min-height: 70vh ; display: flex;align-items: center; justify-content: center;}





